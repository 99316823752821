<template>
  <div class="placeholder">
    <div class="section main-section">
    <div class="main-line" style="width: 167px;"></div>
      <div class="item-line">
        <span></span>
        <div class="line">
          <div style="width: 144px;"></div>
          <div style="width: 147px;"></div>
        </div>
      </div>
      <div class="item-line">
        <span></span>
        <div class="line">
          <div style="width: 81px;"></div>
          <div style="width: 111px;"></div>
        </div>
      </div>
      <div class="item-line">
        <span></span>
        <div class="line">
          <div style="width: 170px;"></div>
          <div style="width: 125px;"></div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="main-line" style="width: 167px;"></div>
      <div class="item-line">
        <span></span>
        <div class="line">
          <div style="width: 144px;"></div>
          <div style="width: 147px;"></div>
        </div>
      </div>
      <div class="item-line">
        <span></span>
        <div class="line">
          <div style="width: 81px;"></div>
          <div style="width: 111px;"></div>
        </div>
      </div>
    </div>
    <div class="last-section">
      <span></span>
      <div></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .placeholder {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    padding-left: 28px;
    height: 100dvh;
    .main-section {
      box-shadow: inset 0 -1px 0 0 #ECE6F2;
    }
    .section {
      display: flex;
      flex-direction: column;
      padding-top: 24px;
      padding-bottom: 26px;
      .main-line {
        height: 9px;
        background: #DCD8E9;
        border-radius: 28px;
      }
      .item-line {
        display: flex;
        margin-top: 30px;
        > span {
          width: 25px;
          height: 25px;
          background: #DCD8E9;
          border-radius: 13px;
          margin-right: 13px;
        }
        .line {
          display: flex;
          flex-direction: column;
          > div {
            height: 6.96px;
            background: #EAE7F3;
            border-radius: 4px;
            &:last-child {
              margin-top: 16px;
            }
          }
        }
      }
    }
    .last-section {
      display: flex;
      position: absolute;
      bottom: 10px;
      > span {
        width: 25px;
        height: 25px;
        background: #DCD8E9;
        border-radius: 13px;
        margin-right: 13px;
      }
      > div {
        align-self: center;
        width: 167px;
        height: 9px;
        background: #DCD8E9;
        border-radius: 28px;
      }
    }
  }
</style>