<template>
  <div class="activate-brand" ref="activateBrand">
    <a-modal
      v-model:open="props.showActivateBrand"
      title="Activate Brand"
      @ok="handleOk"
      :closable="false"
      :width="600"
      okText="Confirm"
      :onCancel="onCancel"
      :getContainer="() => activateBrand"
      :okType="okBtnType"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <p class="text-a">Are you sure you want to activate this brand? This will allow you to activate products for this brand and add them to the Creator marketplace.</p>
      <div class="select-brand">
      <div class="checkbox">
        <a-checkbox
          :disabled="item.active"
          :checked="checkedList.find(i => i === item.value)"
          :key="item.id"
          v-for="item in brandOptions"
          @change="e => changeBrand(e, item.value)"
        >{{item.label}}</a-checkbox>
      </div>
      </div>
      <p class="text-b">I acknowledge that activating this brand will enable my seller account in United States. Once activated, I will be active in the following countries: United States and will have used 1 of my available 1 countries</p>
    </a-modal>
  </div>
</template>

<script setup>

  import { ref, computed, onBeforeMount } from 'vue';

  import { adminApi } from '../../server/index'

  const props = defineProps(['setShowActivateBrand', 'showActivateBrand']);
  const activateBrand = ref(null);
  const checkedList = ref([]);
  const brandList = ref([]);
  const brandOptions = computed(() => {
    let brandOptionsData = [...brandList.value];
    if (brandOptionsData && brandOptionsData.length) {
      brandOptionsData = brandOptionsData.map(item =>( {
        label: item.amazonBrandName,
        value: item.id,
        active: item.status === 'active'
      }))
    }
    return brandOptionsData;
  });

  const onCancel = () => {
    props.setShowActivateBrand(false);
    location.href = '/#/admin/welcome';
  }

  const okBtnType = computed(() => {
    const btnType = checkedList.value.length ? 'primary' : 'ghost';
    return btnType;
  })

  onBeforeMount(() => {
    getSellerBrands();
  })

  const getSellerBrands = async () => {
    const res = await adminApi.getSellerBrands();
    const { success, data } = res;
    if (success) {
      brandList.value = data;
      checkedList.value = data.filter(item => item.status === 'active').map(item => item.id);
    }
  }

  const changeBrand = (e, value) => {
    const checked = e.target.checked;
    if (checked) {
      checkedList.value = [...checkedList.value, value];
    } else {
      checkedList.value = checkedList.value.filter(item => item !== value);
    }
  }

  const handleOk = async e => {
    if (!checkedList.value.length) return;
    const params = checkedList.value.map(item => Number(item));
    const res = await adminApi.activateBrands(params);
    const { success } = res;
      if (success) {
        getSellerBrands();
        props.setShowActivateBrand(false);
        location.href = '/#/admin/welcome';
      }
  };

</script>

<style lang="less" scoped>
  .activate-brand {
    .text-a {
      opacity: 0.5;
      font-family: Sora-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #0F0629;
      letter-spacing: 0.4px;
      line-height: 20px;
    }
    .select-brand {
      margin-top: 24px;
      margin-bottom: 24px;
      .checkbox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        :deep(.ant-checkbox-wrapper) {
          padding-left: 10px;
          display: flex;
          width: 176px;
          height: 40px;
          background: #FFFFFF;
          border: 1px solid #ada1cc;
          box-shadow: 0 1px 0 0 #1a16250d;
          border-radius: 8px;
          > span {
            align-self: center;
          }
          &:nth-child(n+4) {
            margin-top: 10px;
          }
        }
      }
    }
    .text-b {
      font-family: Sora-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #0F0629;
      letter-spacing: 0.4px;
      line-height: 20px;
    }
  }
</style>