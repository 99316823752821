<template>
  <div class="reporting-notice" ref="reportingNoticeModal">
    <a-modal
      v-model:open="props.showReportingNotice"
      title="ArtemisAds Reporting Notice"
      @ok="handleOk"
      :closable="false"
      :width="600"
      okText="I Understand"
      :getContainer="() => reportingNoticeModal"
      :maskClosable="false"
    >
      <p class="text-a">As a part of the Amazon Attribution tracking process, ArtemisAds will create adgroups and campaigns in your Amazon Ads account. The adgroups are needed to attribute conversions accurately.</p>
      <p class="text-b">Do not, under any circumstances, delete or rename adgroups that start with “aa_”</p>
      <p class="text-a">Please notify stakeholders in your organization who have access to Amazon Attribution that ArtemisAds reporting and payment disruption will occur if any changes are made to these adgroups.</p>
    </a-modal>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import jwt from 'jsonwebtoken';

  import { adminApi } from '../../server/index'
  import { useUserInfoStore } from '../../storeData/index';

  const store = useUserInfoStore();
  const props = defineProps(['setShowActivateBrand', 'showReportingNotice', 'setShowReportingNotice']);
  const reportingNoticeModal = ref(null);

  const handleOk = async e => {
    const res = await adminApi.agreeToNotice();
    const { success, data } = res;
    if (success) {
      const userToken = data.token;
      const artmisAdsUserData = jwt.decode(userToken);
      store.setUserInfo(artmisAdsUserData, userToken);
      props.setShowReportingNotice(false);
      props.setShowActivateBrand(true);
    }
  };

</script>

<style lang="less" scoped>
  .reporting-notice {
    display: block;
    :deep(.ant-btn-default) {
      display: none;
    }
    .text-a {
      font-family: Sora-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #0F0629;
      letter-spacing: 0.4px;
      line-height: 20px;
    }
    .text-b {
      margin-top: 16px;
      margin-bottom: 16px;
      padding: 8px 12px;
      font-family: Sora-Medium;
      font-weight: 500;
      font-size: 13px;
      color: #F74531;
      letter-spacing: 0.4px;
      line-height: 18px;
      background: #f745311a;
      border-radius: 8px;
    }
  }
</style>