<template>
  <div class="admin-home">
    <div>
      <left-menu />
    </div>
    <div class="wrap">
      <topBar />
      <div class="content">
        <router-view />
      </div>
    </div>
    <reporting-notice :showReportingNotice="showReportingNotice" :setShowReportingNotice="setShowReportingNotice" :setShowActivateBrand="setShowActivateBrand"/>
    <activate-brand :showActivateBrand="showActivateBrand" :setShowActivateBrand="setShowActivateBrand"/>
  </div>
</template>

<script setup>

  import { onMounted, ref, watch } from 'vue';

  import leftMenu from './leftMenu/index.vue';
  import topBar from '../../components/topBar.vue';
  import reportingNotice from './reportingNotice.vue';
  import activateBrand from './activateBrand.vue';

  import { useUserInfoStore } from '../../storeData/index';

  const store = useUserInfoStore();
  const showReportingNotice = ref(false);
  const showActivateBrand = ref(false);

  const setShowActivateBrand = status => {
    showActivateBrand.value = status;
  }

  const setShowReportingNotice = status => {
    showReportingNotice.value = status;
  }

  const checkStatus = () => {
    const status = store.userInfo.status;
    if (status === 'SELLER_PENDING_AGREED_TO_NOTICE') {
      showReportingNotice.value = true;
    }
  }

  watch(store.userInfo, () => {
    checkStatus();
  })

  onMounted(() => {
    checkStatus()
  })

</script>
<style lang="less" scoped>
  .admin-home {
    display: flex;
    width: 100vw;
    height: 100dvh;
    .wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #F4F2FA;
      .content {
        padding: 24px;
        flex: 1;
      }
    }
  }
</style>
